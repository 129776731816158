import type { ButtonColor } from '#ui/types';

export type PaletteBgColors = Extract<ButtonColor, 'black' | 'white' | 'gray' | 'primary' | 'primary-dark' | 'secondary'>;
type ValuesOf<T> = T[keyof T];

const COLORS: Record<PaletteBgColors, string> = {
    black: 'bg-black',
    white: 'bg-white',
    gray: 'bg-gray-500',
    primary: 'bg-primary-500',
    'primary-dark': 'bg-primary-950',
    secondary: 'bg-secondary-500',
};

export default (color: PaletteBgColors): ValuesOf<typeof COLORS> => COLORS[color];
