
export enum BackendErrorCode {
  NotValidEmail= 'NotValidEmail',
  WeakPassword= 'WeakPassword',
  InvalidEnumValue= 'InvalidEnumValue',
  InvalidUUID= 'InvalidUUID',
  InvalidJWT= 'InvalidJWT',
  InvalidDate= 'InvalidDate',
  InvalidBoolean= 'InvalidBoolean',
  InvalidLength= 'InvalidLength',
  IsNotTrue= 'IsNotTrue',
  NotAString= 'NotAString',
  EmptyString= 'EmptyString',
  InvalidPhoneNumber= 'InvalidPhoneNumber',
  NumberTooSmallThanRelated= 'NumberTooSmallThanRelated',
  NumberTooBigThanRelated= 'NumberTooBigThanRelated',
  NotAWorkingDay= 'NotAWorkingDay',
  PropertyShouldNotExists= 'PropertyShouldNotExists',
  InvalidBidAmount= 'InvalidBidAmount',
  InvalidPropertyByRules= 'InvalidPropertyByRules',
  InvalidBusDossierImageName= 'InvalidBusDossierImageName',
  InvalidBusDossierVideoName= 'InvalidBusDossierVideoName',
  InvalidBusDossierDamageImageName= 'InvalidBusDossierDamageImageName',
  InvalidBusAssetVideoName= 'InvalidBusAssetVideoName',
  EmailAlreadyInUse= 'EmailAlreadyInUse',
  InvalidAuctionState= 'InvalidAuctionState',
  InvalidPayment= 'InvalidPayment',
  PrismaError= 'PrismaError',
  EntityNotFound= 'EntityNotFound',
  EntityAlreadyExists= 'EntityAlreadyExists',
  PasswordResetEmailNotExists= 'PasswordResetEmailNotExists',
  SignupUserAlreadyExists= 'SignupUserAlreadyExists',
  SignupCompanyWithSamePecAlreadyExists= 'SignupCompanyWithSamePecAlreadyExists',
  EmailChangeWrongPassword= 'EmailChangeWrongPassword',
  PasswordDoNotMatch= 'PasswordDoNotMatch',
  AuthWrongCredentials= 'AuthWrongCredentials',
  AuthNoUserByEmail= 'AuthNoUserByEmail',
  AuthNoAdminByEmail= 'AuthNoAdminByEmail',
  AuthWrongPassword= 'AuthWrongPassword',
  AuthTokenNotSubmitted= 'AuthTokenNotSubmitted',
  AuthTokenNotValid= 'AuthTokenNotValid',
  AuthNotEnoughPermission= 'AuthNotEnoughPermission',
  AuthTokenExpired= 'AuthTokenExpired',
  AuthTokenMalformed= 'AuthTokenMalformed',
  AuthEmailNotVerified= 'AuthEmailNotVerified',
  AuthAuthenticationVersionMismatch= 'AuthAuthenticationVersionMismatch',
  AuthRolesNotDefined= 'AuthRolesNotDefined',
  UserIsBanned= 'UserIsBanned',
  AdminCannotBeBanned= 'AdminCannotBeBanned',
  BailNotAuthorized= 'BailNotAuthorized',
  BailCantVoid= 'BailCantVoid',
  UnknownError= 'UnknownError',
  UserHasNoCompany= 'UserHasNoCompany',
  UploadFileError= 'UploadFileError',
  FileNotUploaded= 'FileNotUploaded',
  ErrorDuringClearingCache= 'ErrorDuringClearingCache',
  InvalidCaptcha= 'InvalidCaptcha',
  MissingCaptcha= 'MissingCaptcha',
  AuctionExpired= 'AuctionExpired',
  AuctionNotRunning= 'AuctionNotRunning',
  AuctionNotClosed= 'AuctionNotClosed',
  AuctionClearingPriceNotSettable= 'AuctionClearingPriceNotSettable',
  UserCompanyEqualsToSellerCompany= 'UserCompanyEqualsToSellerCompany',
  ToBeScrapedDoesNotMatch= 'ToBeScrapedDoesNotMatch',
  UserHasNoBail= 'UserHasNoBail',
  AuctionStartDateWeekend= 'AuctionStartDateWeekend',
  AuctionInitialExpiresAtInvalid= 'AuctionInitialExpiresAtInvalid',
  ReservePriceAlreadyReached= 'ReservePriceAlreadyReached',
  AuctionInvalidMainAssetId= 'AuctionInvalidMainAssetId',
  AuctionAssetCongruenceMismatch= 'AuctionAssetCongruenceMismatch',
  AuctionUpdateDenied= 'AuctionUpdateDenied',
  AuctionStartingPriceInvalid= 'AuctionStartingPriceInvalid',
  AuctionSellerReservePriceInvalid= 'AuctionSellerReservePriceInvalid',
  AuctionBidStepInvalid= 'AuctionBidStepInvalid',
  MainAssetWithoutBusAsset= 'MainAssetWithoutBusAsset',
  AuctionInvalidAsset= 'AuctionInvalidAsset',
  InvalidUrlKeyValue= 'InvalidUrlKeyValue',
  CantBidUserCompanyEqSellerCompany= 'CantBidUserCompanyEqSellerCompany',
  CantBidScrapAuctionNotScrapyardCompany= 'CantBidScrapAuctionNotScrapyardCompany',
  CantBidCompanyUnderReview= 'CantBidCompanyUnderReview',
  CantBidAuctionIsNotRunning= 'CantBidAuctionIsNotRunning',
  CantBidUserNoBail= 'CantBidUserNoBail',
  CantBidUnapprovedCompany= 'CantBidUnapprovedCompany',
  CantBidBannedCompany= 'CantBidBannedCompany',
  CantBidRegisteredCompany= 'CantBidRegisteredCompany',
  CantBidUserWithAutoBidActive= 'CantBidUserWithAutoBidActive',
  BidScenarioNotSupported= 'BidScenarioNotSupported',
  BidScenarioNotConfigured= 'BidScenarioNotConfigured',
  CantBidUserWithoutCompany= 'CantBidUserWithoutCompany',
  CantBidCompanyNotYetApprovedForScrap= 'CantBidCompanyNotYetApprovedForScrap',
  CantBidCompanyNotApprovedForScrap= 'CantBidCompanyNotApprovedForScrap',
  CantBidUnhandledScenario= 'CantBidUnhandledScenario',
  InvalidAutoBidAmount= 'InvalidAutoBidAmount',
  InvalidAutoBidId= 'InvalidAutoBidId',
  CantAutoBid= 'CantAutoBid',
  BidNotCreated= 'BidNotCreated',
  InvalidUserAutoBid= 'InvalidUserAutoBid',
  InvalidAuctionAutoBid= 'InvalidAuctionAutoBid',
  UnexpectedAutoBidData= 'UnexpectedAutoBidData',
  PaymentProviderError= 'PaymentProviderError',
  BusDossierPublishingFailed= 'BusDossierPublishingFailed',
  WinningBidNotFound= 'WinningBidNotFound',
  CountryCodeNotFound= 'CountryCodeNotFound',
  InvalidTaxCodeCombination= 'InvalidTaxCodeCombination',
  VatNumberNotFound= 'VatNumberNotFound',
  NegotiationBuyerProposalNotValid= 'NegotiationBuyerProposalNotValid',
  NegotiationSellerProposalNotValid= 'NegotiationSellerProposalNotValid',
  NegotiationNotOpen= 'NegotiationNotOpen',
  NegotiationStateInvalid= 'NegotiationStateInvalid',
  NegotiationInvalidParticipant= 'NegotiationInvalidParticipant',
  NegotiationProposalNotFound= 'NegotiationProposalNotFound',
  NegotiationNextParticipantNotFound= 'NegotiationNextParticipantNotFound',

}