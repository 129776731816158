import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  AccountExistResponseDto,
  AdminPasswordResetBodyDto,
  AdminPasswordResetResponseDto,
  AdminUserForResponse,
  ApiAccountAuctionsBuyerParams,
  ApiAccountAuctionsSellerParams,
  ApiAccountExistParams,
  ApiConfirmEmailChangeParams,
  AuctionsListingDto,
  BackendErrorEntity,
  ChangeAccountEmailDto,
  ChangeMarketingCommunicationsConsentDto,
  ChangePasswordDto,
  ChangePasswordResponseDto,
  PasswordResetDto,
  RequestAdminPasswordResetBodyDto,
  RequestPasswordResetDto,
  UpdateAccountDto,
  UserEntity
} from '.././types'
import { fetchApiInstance } from '../../fetchApiInstance';
import type { ErrorType, BodyType } from '../../fetchApiInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const apiAccountExist = (
    params: MaybeRef<ApiAccountExistParams>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      params = unref(params);
      
      return fetchApiInstance<AccountExistResponseDto>(
      {url: `/account/exist`, method: 'GET',
        params: unref(params)
    },
      options);
    }
  

export const getApiAccountExistQueryKey = (params: MaybeRef<ApiAccountExistParams>,) => {
    return ['account','exist', ...(params ? [params]: [])] as const;
    }

    
export const getApiAccountExistQueryOptions = <TData = Awaited<ReturnType<typeof apiAccountExist>>, TError = ErrorType<BackendErrorEntity>>(params: MaybeRef<ApiAccountExistParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAccountExist>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiAccountExistQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiAccountExist>>> = () => apiAccountExist(params, requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiAccountExist>>, TError, TData> 
}

export type ApiAccountExistQueryResult = NonNullable<Awaited<ReturnType<typeof apiAccountExist>>>
export type ApiAccountExistQueryError = ErrorType<BackendErrorEntity>



export function useApiAccountExist<TData = Awaited<ReturnType<typeof apiAccountExist>>, TError = ErrorType<BackendErrorEntity>>(
 params: MaybeRef<ApiAccountExistParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAccountExist>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiAccountExistQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiAccountAuctionsSeller = (
    params?: MaybeRef<ApiAccountAuctionsSellerParams>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      params = unref(params);
      
      return fetchApiInstance<AuctionsListingDto>(
      {url: `/account/auctions/seller`, method: 'GET',
        params: unref(params)
    },
      options);
    }
  

export const getApiAccountAuctionsSellerQueryKey = (params?: MaybeRef<ApiAccountAuctionsSellerParams>,) => {
    return ['account','auctions','seller', ...(params ? [params]: [])] as const;
    }

    
export const getApiAccountAuctionsSellerQueryOptions = <TData = Awaited<ReturnType<typeof apiAccountAuctionsSeller>>, TError = ErrorType<BackendErrorEntity>>(params?: MaybeRef<ApiAccountAuctionsSellerParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAccountAuctionsSeller>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiAccountAuctionsSellerQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiAccountAuctionsSeller>>> = () => apiAccountAuctionsSeller(params, requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiAccountAuctionsSeller>>, TError, TData> 
}

export type ApiAccountAuctionsSellerQueryResult = NonNullable<Awaited<ReturnType<typeof apiAccountAuctionsSeller>>>
export type ApiAccountAuctionsSellerQueryError = ErrorType<BackendErrorEntity>



export function useApiAccountAuctionsSeller<TData = Awaited<ReturnType<typeof apiAccountAuctionsSeller>>, TError = ErrorType<BackendErrorEntity>>(
 params?: MaybeRef<ApiAccountAuctionsSellerParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAccountAuctionsSeller>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiAccountAuctionsSellerQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiAccountAuctionsBuyer = (
    params?: MaybeRef<ApiAccountAuctionsBuyerParams>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      params = unref(params);
      
      return fetchApiInstance<AuctionsListingDto>(
      {url: `/account/auctions/buyer`, method: 'GET',
        params: unref(params)
    },
      options);
    }
  

export const getApiAccountAuctionsBuyerQueryKey = (params?: MaybeRef<ApiAccountAuctionsBuyerParams>,) => {
    return ['account','auctions','buyer', ...(params ? [params]: [])] as const;
    }

    
export const getApiAccountAuctionsBuyerQueryOptions = <TData = Awaited<ReturnType<typeof apiAccountAuctionsBuyer>>, TError = ErrorType<BackendErrorEntity>>(params?: MaybeRef<ApiAccountAuctionsBuyerParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAccountAuctionsBuyer>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiAccountAuctionsBuyerQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiAccountAuctionsBuyer>>> = () => apiAccountAuctionsBuyer(params, requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiAccountAuctionsBuyer>>, TError, TData> 
}

export type ApiAccountAuctionsBuyerQueryResult = NonNullable<Awaited<ReturnType<typeof apiAccountAuctionsBuyer>>>
export type ApiAccountAuctionsBuyerQueryError = ErrorType<BackendErrorEntity>



export function useApiAccountAuctionsBuyer<TData = Awaited<ReturnType<typeof apiAccountAuctionsBuyer>>, TError = ErrorType<BackendErrorEntity>>(
 params?: MaybeRef<ApiAccountAuctionsBuyerParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAccountAuctionsBuyer>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiAccountAuctionsBuyerQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiRequestPasswordReset = (
    requestPasswordResetDto: MaybeRef<RequestPasswordResetDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      requestPasswordResetDto = unref(requestPasswordResetDto);
      
      return fetchApiInstance<void>(
      {url: `/account/requestPasswordReset`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: requestPasswordResetDto
    },
      options);
    }
  


export const getApiRequestPasswordResetMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiRequestPasswordReset>>, TError,{data: BodyType<RequestPasswordResetDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiRequestPasswordReset>>, TError,{data: BodyType<RequestPasswordResetDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiRequestPasswordReset>>, {data: BodyType<RequestPasswordResetDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiRequestPasswordReset(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiRequestPasswordResetMutationResult = NonNullable<Awaited<ReturnType<typeof apiRequestPasswordReset>>>
    export type ApiRequestPasswordResetMutationBody = BodyType<RequestPasswordResetDto>
    export type ApiRequestPasswordResetMutationError = ErrorType<BackendErrorEntity>

    export const useApiRequestPasswordReset = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiRequestPasswordReset>>, TError,{data: BodyType<RequestPasswordResetDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiRequestPasswordReset>>,
        TError,
        {data: BodyType<RequestPasswordResetDto>},
        TContext
      > => {

      const mutationOptions = getApiRequestPasswordResetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiPasswordReset = (
    passwordResetDto: MaybeRef<PasswordResetDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      passwordResetDto = unref(passwordResetDto);
      
      return fetchApiInstance<UserEntity>(
      {url: `/account/passwordReset`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: passwordResetDto
    },
      options);
    }
  


export const getApiPasswordResetMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiPasswordReset>>, TError,{data: BodyType<PasswordResetDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiPasswordReset>>, TError,{data: BodyType<PasswordResetDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiPasswordReset>>, {data: BodyType<PasswordResetDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiPasswordReset(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiPasswordResetMutationResult = NonNullable<Awaited<ReturnType<typeof apiPasswordReset>>>
    export type ApiPasswordResetMutationBody = BodyType<PasswordResetDto>
    export type ApiPasswordResetMutationError = ErrorType<BackendErrorEntity>

    export const useApiPasswordReset = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiPasswordReset>>, TError,{data: BodyType<PasswordResetDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiPasswordReset>>,
        TError,
        {data: BodyType<PasswordResetDto>},
        TContext
      > => {

      const mutationOptions = getApiPasswordResetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiUpdateAccountPersonalData = (
    updateAccountDto: MaybeRef<UpdateAccountDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      updateAccountDto = unref(updateAccountDto);
      
      return fetchApiInstance<UserEntity>(
      {url: `/account`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateAccountDto
    },
      options);
    }
  


export const getApiUpdateAccountPersonalDataMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateAccountPersonalData>>, TError,{data: BodyType<UpdateAccountDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUpdateAccountPersonalData>>, TError,{data: BodyType<UpdateAccountDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUpdateAccountPersonalData>>, {data: BodyType<UpdateAccountDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiUpdateAccountPersonalData(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUpdateAccountPersonalDataMutationResult = NonNullable<Awaited<ReturnType<typeof apiUpdateAccountPersonalData>>>
    export type ApiUpdateAccountPersonalDataMutationBody = BodyType<UpdateAccountDto>
    export type ApiUpdateAccountPersonalDataMutationError = ErrorType<BackendErrorEntity>

    export const useApiUpdateAccountPersonalData = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateAccountPersonalData>>, TError,{data: BodyType<UpdateAccountDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUpdateAccountPersonalData>>,
        TError,
        {data: BodyType<UpdateAccountDto>},
        TContext
      > => {

      const mutationOptions = getApiUpdateAccountPersonalDataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiChangePassword = (
    changePasswordDto: MaybeRef<ChangePasswordDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      changePasswordDto = unref(changePasswordDto);
      
      return fetchApiInstance<ChangePasswordResponseDto>(
      {url: `/account/changePassword`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: changePasswordDto
    },
      options);
    }
  


export const getApiChangePasswordMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiChangePassword>>, TError,{data: BodyType<ChangePasswordDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiChangePassword>>, TError,{data: BodyType<ChangePasswordDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiChangePassword>>, {data: BodyType<ChangePasswordDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiChangePassword(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiChangePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof apiChangePassword>>>
    export type ApiChangePasswordMutationBody = BodyType<ChangePasswordDto>
    export type ApiChangePasswordMutationError = ErrorType<BackendErrorEntity>

    export const useApiChangePassword = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiChangePassword>>, TError,{data: BodyType<ChangePasswordDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiChangePassword>>,
        TError,
        {data: BodyType<ChangePasswordDto>},
        TContext
      > => {

      const mutationOptions = getApiChangePasswordMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiChangeMarketingCommunicationsConsent = (
    changeMarketingCommunicationsConsentDto: MaybeRef<ChangeMarketingCommunicationsConsentDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      changeMarketingCommunicationsConsentDto = unref(changeMarketingCommunicationsConsentDto);
      
      return fetchApiInstance<void>(
      {url: `/account/changeMarketingCommunicationsConsent`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: changeMarketingCommunicationsConsentDto
    },
      options);
    }
  


export const getApiChangeMarketingCommunicationsConsentMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiChangeMarketingCommunicationsConsent>>, TError,{data: BodyType<ChangeMarketingCommunicationsConsentDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiChangeMarketingCommunicationsConsent>>, TError,{data: BodyType<ChangeMarketingCommunicationsConsentDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiChangeMarketingCommunicationsConsent>>, {data: BodyType<ChangeMarketingCommunicationsConsentDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiChangeMarketingCommunicationsConsent(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiChangeMarketingCommunicationsConsentMutationResult = NonNullable<Awaited<ReturnType<typeof apiChangeMarketingCommunicationsConsent>>>
    export type ApiChangeMarketingCommunicationsConsentMutationBody = BodyType<ChangeMarketingCommunicationsConsentDto>
    export type ApiChangeMarketingCommunicationsConsentMutationError = ErrorType<BackendErrorEntity>

    export const useApiChangeMarketingCommunicationsConsent = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiChangeMarketingCommunicationsConsent>>, TError,{data: BodyType<ChangeMarketingCommunicationsConsentDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiChangeMarketingCommunicationsConsent>>,
        TError,
        {data: BodyType<ChangeMarketingCommunicationsConsentDto>},
        TContext
      > => {

      const mutationOptions = getApiChangeMarketingCommunicationsConsentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiChangeAccountEmail = (
    changeAccountEmailDto: MaybeRef<ChangeAccountEmailDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      changeAccountEmailDto = unref(changeAccountEmailDto);
      
      return fetchApiInstance<void>(
      {url: `/account/changeEmail`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: changeAccountEmailDto
    },
      options);
    }
  


export const getApiChangeAccountEmailMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiChangeAccountEmail>>, TError,{data: BodyType<ChangeAccountEmailDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiChangeAccountEmail>>, TError,{data: BodyType<ChangeAccountEmailDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiChangeAccountEmail>>, {data: BodyType<ChangeAccountEmailDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiChangeAccountEmail(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiChangeAccountEmailMutationResult = NonNullable<Awaited<ReturnType<typeof apiChangeAccountEmail>>>
    export type ApiChangeAccountEmailMutationBody = BodyType<ChangeAccountEmailDto>
    export type ApiChangeAccountEmailMutationError = ErrorType<BackendErrorEntity>

    export const useApiChangeAccountEmail = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiChangeAccountEmail>>, TError,{data: BodyType<ChangeAccountEmailDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiChangeAccountEmail>>,
        TError,
        {data: BodyType<ChangeAccountEmailDto>},
        TContext
      > => {

      const mutationOptions = getApiChangeAccountEmailMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiConfirmEmailChange = (
    params: MaybeRef<ApiConfirmEmailChangeParams>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      params = unref(params);
      
      return fetchApiInstance<string>(
      {url: `/account/confirmEmail`, method: 'GET',
        params: unref(params)
    },
      options);
    }
  

export const getApiConfirmEmailChangeQueryKey = (params: MaybeRef<ApiConfirmEmailChangeParams>,) => {
    return ['account','confirmEmail', ...(params ? [params]: [])] as const;
    }

    
export const getApiConfirmEmailChangeQueryOptions = <TData = Awaited<ReturnType<typeof apiConfirmEmailChange>>, TError = ErrorType<BackendErrorEntity>>(params: MaybeRef<ApiConfirmEmailChangeParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiConfirmEmailChange>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiConfirmEmailChangeQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiConfirmEmailChange>>> = () => apiConfirmEmailChange(params, requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiConfirmEmailChange>>, TError, TData> 
}

export type ApiConfirmEmailChangeQueryResult = NonNullable<Awaited<ReturnType<typeof apiConfirmEmailChange>>>
export type ApiConfirmEmailChangeQueryError = ErrorType<BackendErrorEntity>



export function useApiConfirmEmailChange<TData = Awaited<ReturnType<typeof apiConfirmEmailChange>>, TError = ErrorType<BackendErrorEntity>>(
 params: MaybeRef<ApiConfirmEmailChangeParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiConfirmEmailChange>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiConfirmEmailChangeQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiAdminMe = (
    
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      
      
      return fetchApiInstance<AdminUserForResponse>(
      {url: `/account/admin/me`, method: 'GET'
    },
      options);
    }
  

export const getApiAdminMeQueryKey = () => {
    return ['account','admin','me'] as const;
    }

    
export const getApiAdminMeQueryOptions = <TData = Awaited<ReturnType<typeof apiAdminMe>>, TError = ErrorType<BackendErrorEntity>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAdminMe>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiAdminMeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiAdminMe>>> = () => apiAdminMe(requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiAdminMe>>, TError, TData> 
}

export type ApiAdminMeQueryResult = NonNullable<Awaited<ReturnType<typeof apiAdminMe>>>
export type ApiAdminMeQueryError = ErrorType<BackendErrorEntity>



export function useApiAdminMe<TData = Awaited<ReturnType<typeof apiAdminMe>>, TError = ErrorType<BackendErrorEntity>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAdminMe>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiAdminMeQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiRequestAdminPasswordReset = (
    requestAdminPasswordResetBodyDto: MaybeRef<RequestAdminPasswordResetBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      requestAdminPasswordResetBodyDto = unref(requestAdminPasswordResetBodyDto);
      
      return fetchApiInstance<void>(
      {url: `/account/admin/requestPasswordReset`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: requestAdminPasswordResetBodyDto
    },
      options);
    }
  


export const getApiRequestAdminPasswordResetMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiRequestAdminPasswordReset>>, TError,{data: BodyType<RequestAdminPasswordResetBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiRequestAdminPasswordReset>>, TError,{data: BodyType<RequestAdminPasswordResetBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiRequestAdminPasswordReset>>, {data: BodyType<RequestAdminPasswordResetBodyDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiRequestAdminPasswordReset(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiRequestAdminPasswordResetMutationResult = NonNullable<Awaited<ReturnType<typeof apiRequestAdminPasswordReset>>>
    export type ApiRequestAdminPasswordResetMutationBody = BodyType<RequestAdminPasswordResetBodyDto>
    export type ApiRequestAdminPasswordResetMutationError = ErrorType<BackendErrorEntity>

    export const useApiRequestAdminPasswordReset = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiRequestAdminPasswordReset>>, TError,{data: BodyType<RequestAdminPasswordResetBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiRequestAdminPasswordReset>>,
        TError,
        {data: BodyType<RequestAdminPasswordResetBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiRequestAdminPasswordResetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiAdminPasswordReset = (
    adminPasswordResetBodyDto: MaybeRef<AdminPasswordResetBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      adminPasswordResetBodyDto = unref(adminPasswordResetBodyDto);
      
      return fetchApiInstance<AdminPasswordResetResponseDto>(
      {url: `/account/admin/passwordReset`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: adminPasswordResetBodyDto
    },
      options);
    }
  


export const getApiAdminPasswordResetMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiAdminPasswordReset>>, TError,{data: BodyType<AdminPasswordResetBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiAdminPasswordReset>>, TError,{data: BodyType<AdminPasswordResetBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiAdminPasswordReset>>, {data: BodyType<AdminPasswordResetBodyDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiAdminPasswordReset(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiAdminPasswordResetMutationResult = NonNullable<Awaited<ReturnType<typeof apiAdminPasswordReset>>>
    export type ApiAdminPasswordResetMutationBody = BodyType<AdminPasswordResetBodyDto>
    export type ApiAdminPasswordResetMutationError = ErrorType<BackendErrorEntity>

    export const useApiAdminPasswordReset = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiAdminPasswordReset>>, TError,{data: BodyType<AdminPasswordResetBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiAdminPasswordReset>>,
        TError,
        {data: BodyType<AdminPasswordResetBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiAdminPasswordResetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    