import { readonly, type Ref, type ComputedRef } from 'vue';

import { TimeSyncStatus } from '~/types/timeSync.type';

type UseTimeSyncReturn = {
    timeSyncNow: ComputedRef<number>;
    timeSyncOffset: Readonly<Ref<number | null>>;
    timeSyncRoundTrip: Readonly<Ref<number | null>>;
    timeSyncStatus: Readonly<Ref<TimeSyncStatus>>;
    _raw: {
        now: Ref<number>;
        offset: Ref<number | null>;
        roundTrip: Ref<number | null>;
        status: Ref<TimeSyncStatus>;
    };
};

export const useTimeSync = (): UseTimeSyncReturn => {
    const now = useState<number>('timeSync', () => Date.now());
    const offset = useState<number | null>('timeSyncOffset', () => null);
    const roundTrip = useState<number | null>('timeSyncRoundTrip', () => null);
    const status = useState<TimeSyncStatus>('timeSyncStatus', () => TimeSyncStatus.Pending);

    // Use static initialNow for initial server/client render, then timeSyncNow on mounted, to avoid SSR hydration issues
    const isMounted = ref(false);
    const initialNow = useState<number>(() => Date.now());

    // Enable onMounted behaviour only for components, avoid it for timeSync plugin
    // to prevent warning: "onMounted is called when there is no active component instance to be associated with"
    if (getCurrentInstance()) {
        onMounted(() => (isMounted.value = true));
    }

    return {
        timeSyncNow: computed(() => (isMounted.value ? now.value : initialNow.value)),
        timeSyncOffset: readonly(offset),
        timeSyncRoundTrip: readonly(roundTrip),
        timeSyncStatus: readonly(status),
        // For exclusive use of the timeSync plugin
        _raw: {
            now,
            offset,
            roundTrip,
            status,
        },
    };
};
