import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAlertOutline, LazySvgoAssetBus, LazySvgoBusIllustration, LazySvgoBusPlaceholder, LazySvgoFleequidLogo, LazySvgoSteeringWheel, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["SvgoAlertOutline", LazySvgoAlertOutline],
["SvgoAssetBus", LazySvgoAssetBus],
["SvgoBusIllustration", LazySvgoBusIllustration],
["SvgoBusPlaceholder", LazySvgoBusPlaceholder],
["SvgoFleequidLogo", LazySvgoFleequidLogo],
["SvgoSteeringWheel", LazySvgoSteeringWheel],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
