
export enum AuctionsWsOutEvent {
  AuthorizeSuccess= 'AuthorizeSuccess',
  UnauthorizeSuccess= 'UnauthorizeSuccess',
  AuctionDetailSnapshot= 'AuctionDetailSnapshot',
  AuctionListingSnapshot= 'AuctionListingSnapshot',
  UserMetaSnapshot= 'UserMetaSnapshot',
  Pong= 'Pong',
  AuctionSnapshotUpdate= 'AuctionSnapshotUpdate',
  UserOutBid= 'UserOutBid',
  UserWinningBid= 'UserWinningBid',
  UserAutoBidMaxThresholdReached= 'UserAutoBidMaxThresholdReached',
  UserMaxBidderRestored= 'UserMaxBidderRestored',
  UserBailAuthorized= 'UserBailAuthorized',
  UserOffersUpdate= 'UserOffersUpdate',
  AuctionWon= 'AuctionWon',
  NegotiationStarted= 'NegotiationStarted',
  NegotiationExpired= 'NegotiationExpired',
  NegotiationProposalByBuyer= 'NegotiationProposalByBuyer',
  NegotiationProposalBySeller= 'NegotiationProposalBySeller',
  NegotiationAcceptedByBuyer= 'NegotiationAcceptedByBuyer',
  NegotiationAcceptedBySeller= 'NegotiationAcceptedBySeller',
  NegotiationsDeclinedBySeller= 'NegotiationsDeclinedBySeller',
  NegotiationStoppedProposalsByBuyer= 'NegotiationStoppedProposalsByBuyer',

}